/** file: /components/login.css **/

.login {
	flex-direction: column;
	margin: auto;
	padding: 1em 2em;
	min-width: 260px;
	max-width: 340px;
}

.login h1 {
	text-align: center;
}

.logo {
	position: relative;
	width: 133px;
	height: auto;
	margin: auto;
}

.field {
	width: 100%;
	position: relative;
	border-bottom: 1px dashed;
	margin: 2rem auto 1rem;
	transition: 300ms;
}

.label {
	font-size: 0.9rem;
}

.input {
	outline: none;
	border: none;
	overflow: hidden;
	margin: 0;
	width: 100%;
	padding: 0.25rem 0;
	font-size: 1em;
	background: transparent;
	transition: border 500ms;
}

.field::after {
	content: '';
	position: relative;
	display: block;
	height: 4px;
	width: 100%;
	background: var(--orange);
	transform: scaleX(0);
	transform-origin: 0%;
	opacity: 0;
	transition: all 300ms ease;
	top: 2px;
}

.field:focus-within {
	border-color: transparent;
}
.field:focus-within::after {
	transform: scaleX(1);
	opacity: 1;
}

.label {
	position: absolute;
	transform: translateY(-1rem);
	transform-origin: 0%;
	transition: transform 400ms;
	pointer-events: none;
}

.field:focus-within .label,
.input:not(:placeholder-shown) + .label {
	transform: scale(0.8) translateY(-3.5rem);
}

.forgotPassword {
	width: 100%;
	text-align: right;
	font-size: 0.8em;
	margin-top: -1em;
	font-weight: lighter;
	cursor: pointer;
}

.button {
	cursor: pointer;
	background-color: var(--orange);
	color: var(--black);
	border: 1px solid var(--orange);
	margin: 1em auto;
	transition: all 200ms ease;
}

.button:hover {
	color: var(--white);
	background-color: var(--dark-green);
}
.link {
	text-decoration: underline;
	cursor: pointer;
	color: var(--sanguine);
}
